import $ from 'jquery'
export default {
    requestDaoyuAuthCode,
}

/**
 * 获取叨鱼授权码
 * @param daoyuTraceId
 * @param onSuccess
 * @param onFailed
 */
function requestDaoyuAuthCode(daoyuTraceId, onSuccess, onFailed) {
    // console.log("请求DY授权码...");
    let traceId = daoyuTraceId ? daoyuTraceId : "fbbac900-7cb5-499d-854a-7220b949145d";
    $.ajax({
        url: 'https://cas.sdo.com/authen/thirdParty/getGameLoginAuthorization.json',
        cache: false,
        data: { traceId: traceId, appId: "199", fn_callback: "test", "authenSource": 2 },
        dataType: "jsonp",
        jsonpCallback: "test",
        success: function(respData) {
            if (!respData.return_code) {
                let auth_code = respData.data.authorization;
                //console.log("返回的数据" + JSON.stringify(respData));
                // console.log("已获取DY授权码:" + auth_code);
                onSuccess(auth_code);
            } else {
                onFailed();
            }
        },
        error: function(jqxhr, code, msg) {
            // console.log("获取DY授权码失败" + msg);
        },
    });
}