import localGameState from '@/utils/localGameState'
import lcgWsUtil from '@/utils/lcgWsUtil'
import TCGSDK from 'tcgsdk'
import daoyuDependent from "@/components/daoyu/DaoyuDependent";
import helper from "@/utils/helper";
import TRTC from 'trtc-js-sdk';

export default {
    data () {
        return {
            keepStatus: false,
            gameSuccess: false,
            dialogStatus: false,
            landscape:false, //横屏
            play_token: {},
            game_params: {},
            cloud_provider: '',
            cloud_server: '',
            mobileGameRotateContainer: false,
            isTencentMobileGame: false,
            ali_loading: true
        }
    },
    create() {},
    mounted() {},
    computed: {
        // 刚加载时游戏未启动无游戏信息
        selectedGameName:function(){
            this.gameName ? this.gameName : (localGameState.getSelectedGame() ? localGameState.getSelectedGame().game_name : '');
            this.gameName ? document.title = '传奇云游戏 | ' + this.gameName : document.title = '传奇云游戏 | ' + '让经典游戏再续传奇';
            return this.gameName;
        },
        // 获取存档按钮的显示隐藏
        getArchive:function(){
            return  localGameState.getSelectedGame()?localGameState.getSelectedGame().game_archive : 0;
        },
        // 是否支持充值
        supportRecharge: function(){
            return localGameState.getSelectedGame()?localGameState.getSelectedGame().is_recharge : 0;
        },

        // 串起用户行为的会话id
        session: function () {
            return localGameState.getSessionId();
        },

        // 当前游戏区
        gameArea: function () {
            return localGameState.getNowGameArea();
        },

        // 获取当前的游戏状态
        gameState: function () {
            return this.$store.state.game.game_state || '';
        },
        //游戏输入框原有的信息
        gameInputMsg: {
            get () {
                return this.$store.state.game.game_input_msg ?? '';
            },
            set (value) {
                this.$store.commit('GAME_INPUT_MSG', value);
            }
        },
        ali_status() {
            if (this.cloud_provider == 'ali' && this.ali_loading) {
                return true
            } else {
                return false
            }
        }
    },
    watch: {
        "$store.state.game.recharge_url"(state) {
            state ? window.open (state, '', '', true) : ''
        },
        // 监听游戏启动成功
        gameSuccess(state){
            if (state) {
                this.thisTimeCount = 0;
                localGameState.startGame();
            } else {
                this.thisTimeCount = '';
            }
        },
        // 游戏启动成功后开启倒计时
        thisTimeCount(state){
            if (this.gameSuccess) {
                let t = setInterval(()=>{
                    this.thisTimeCount = state + 1
                    this.gameTimeCount = this.gameTimeCount + 1
                    clearInterval(t)
                },1000)
            }
        },

        // session_id
        session(state){
            state ? this.session_id = state : '';
        },
        commentStatus(state) {
            if (this.cloud_provider == 'ali') {
                state ? CGSDK.getControllerManager().setHidConfig(5,false) : CGSDK.getControllerManager().setHidConfig(5,true)
            }
        },
        "$store.state.game.game_input"(state) {
            if (this.cloud_provider == 'ali') {
                state ? CGSDK.getControllerManager().setHidConfig(5,false) : ''
            }
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    },
    methods: {
        changeVoiceStatus() {
            this.cloud_provider == 'tencent' ? TCGSDK.setVideoVolume(this.voiceStatus/100) : CGSDK.getInteractManager().setVolume(this.voiceStatus/100)
        },
        changeCommentStatus() {
            this.commentStatus = false
        },
        // 存档功能
        archive() {
            if (!this.keepStatus) {
                this.keepStatus = true
                let keep_msg = lcgWsUtil.wsMessageBody(300098, '', '', '', '', '', '','');
                this._ws_connection.emit("gamingControlFlow", keep_msg);
                clearTimeout(times)
            } else {
                this.$store.commit('MESSAGE_TEXT','请勿重复存档,稍后再试')
                return false
            }
            var times = setTimeout(()=>{
                this.keepStatus = false
            },10000)
        },
        inputText() {
            let inputMsg = this.gameInputMsg;
            if (this.$store.state.game.game_info.launch_params.dispatch_type == "player_instance") {
                let text = lcgWsUtil.systemBase64Encode(inputMsg);
                let input_msg = lcgWsUtil.wsMessageBody(30010, '', '', '', '', '', '', text);
                this._ws_connection.emit("gamingControlFlow", input_msg);
            } else {
                TCGSDK.sendText(inputMsg)
            }
        },
        // 文本输入
        inputSure() {
            if(this.$store.state.game.game_input_type == 1) {
                if (this.cloud_provider == 'tencent') {
                    TCGSDK.sendKeyboardEvent({key: 13, down: true})
                    TCGSDK.sendKeyboardEvent({key: 13, down: false})
                } else if (this.cloud_provider == 'ali') {
                    CGSDK.getControllerManager().setHidConfig(5,true)
                    CGSDK.getControllerManager().customKeyboardEvent({
                        event: 66, // 键值
                        action: 'down',
                    });
                    CGSDK.getControllerManager().customKeyboardEvent({
                        event: 66, // 键值
                        action: 'up',
                    });
                }
            }
            this.$store.commit('GAME_INPUT',false)
            this.$store.commit('GAME_INPUT_MSG', '')
        },
        // 关闭游戏页面的动画
        stopGamePageAnimation(){
            this.gameSuccess = false;
            if (this.cloudApp) {
                this.cloudApp.exit();
                return;
            }
        },
        // 查询叨鱼登录信息,已登录则直接启动游戏,失败则跳转到叨鱼登录页面
        querySdoLoginInfo() {
            let daoyuTraceId = new Date().getTime();
            this.callback_onDaoyuCheckLoginSuccess(daoyuTraceId);
        },
        // 检查叨鱼登录成功后获取叨鱼授权码
        callback_onDaoyuCheckLoginSuccess(daoyuTraceId) {
            daoyuDependent.requestDaoyuAuthCode(daoyuTraceId, this.requestGamePageInitParams, this.skipDaoyuLoginPage);
        },
        // 跳转叨鱼登录页面
        skipDaoyuLoginPage() {
            if(!this.landscape && helper.isMobile()) return;
            let fullUrl = this.$store.state.baseUrl + '/daoyu/sdo_login_skip?game_id=' + this.game_id;
            location.href = "https://login.u.sdo.com/sdo/Login/LoginSDO.php?appId=" + this.daoyuAppId + "&areaId=1&service=" + encodeURIComponent(fullUrl);
        },
        // 获取游戏页面的初始化参数
        requestGamePageInitParams(authorizationCode = '', launchType = '', daoyuTicket = ''){
            if (this.isSdo) {
                this.$API.daoyuGameInitParams({ authorization_code: authorizationCode, game_id: this.game_id, daoyu_ticket: daoyuTicket }).then((response)=>{
                    this.initPageParams(response.data);
                })
            } else {
                this.$API.getStartCloudGame({game_id: this.game_id, type: launchType}).then((response)=>{
                    this.initPageParams(response.data);
                })
            }
        },
        // 打开充值界面
        openRechargeLink() {
            let rechargeInfo = lcgWsUtil.wsMessageBody(10290, '', '', '', '', '', '', '');
            this._ws_connection.emit("report", rechargeInfo);
        },
        // 云游戏启动准备
        gameReady() {
            window.playTencentGame = this.playTencentGame;
            this.$eventBus.$on("CloseCloudGame", (game) => {
                switch (this.cloud_provider) {
                    case 'ub':
                        this._onnine.stopAsync();break;
                    case 'tencent':
                    case 'tencent_arm':
                        TCGSDK.destroy();break;
                    case "huawei":
                        this.cloudApp.exit();break;
                    default:
                        this.$refs.launchGame.stop_game(true)
                }
            });
            this.wsToken = this.$route.query.tmp_token;
            this.daoyuTicket = this.$route.query.ticket;
            //sdo平台的游戏启动
            if (this.isSdo && this.daoyuTicket) {
                //启动游戏
                window.performance.navigation.type == 0 ? this.requestGamePageInitParams('', 'launch_game', this.daoyuTicket) : this.querySdoLoginInfo();
            } else if (this.isSdo && !this.daoyuTicket) {
                // 查询用户信息
                this.querySdoLoginInfo();
            } else if (this.$route.name == 'Partner') {//预言游戏接入
                this.gameName = '预言';
                if (this.$route.path.indexOf('phonegame')>0) {
                    this.landscape ? this.$refs.launchGame.requestStartCloudGame('', this.wsToken) : ''
                } else {
                    this.gameWidth = this.initGameWidth = 1024;
                    this.gameHeight = this.initGameHeight = 768;
                    this.loadingStatus = true;
                    this.onWindowResized();
                    this.$refs.launchGame.requestStartCloudGame('', this.wsToken);
                }
            } else {
                let launchType = 'launch_game';
                if (this.$route.name == 'Trial') launchType = 'try_play_game';
                this.requestGamePageInitParams('', launchType)
            }
        },
        // 子组件改变弹窗状态
        changeDialogStatus (status) {
            this.dialogStatus = status
        },
        // 关闭游戏
        close_game(){
            this.$refs.launchGame.confirm_exit_game();
        },
        startCloudGame(obj){
            let game_info = this.$store.state.game.game_info
            if(!game_info){
                localGameState.stopGame('游戏启动参数异常');
            }
            this.play_token = game_info.launch_params.play_token;
            this.game_params = game_info.launch_params.game_params;
            this.cloud_provider = game_info.launch_params.cloud_provider;
            this.cloud_server = game_info.launch_params.cloud_server;
            switch (this.cloud_provider) {
                case "huawei":
                    this.startHuaweiGame(obj,this._startCloudGameFailed);
                    break;
                case "tencent":
                    this.startTencentGame(obj,this._startCloudGameFailed);
                    break;
                case "tencent_arm":
                    this.isTencentMobileGame = true;
                    this.startTencentGame(obj,this._startCloudGameFailed);
                    break;
                case "ub":
                    this.startUbitusGame(obj,this._startCloudGameFailed);
                    break;
                case "ali":
                    this.startAliGame(obj);
                    break;
                default:
                    localGameState.stopGame('不存在的cloud server:' + this.cloud_provider)
                    return;
            }
            let success_msg = lcgWsUtil.wsMessageBody(10008);
            obj.emit("report", success_msg);
        },
        _startCloudGameFailed(error){
            //this.$refs.launchGame.onCloudGameError(error);
            localGameState.stopGame(error);
        },
        playTencentGame(server_session){
            TCGSDK.start(server_session);
            document.body.onfocus = function(e) {
                if(TCGSDK && TCGSDK.clearRemoteKeys) {
                    TCGSDK.clearRemoteKeys();
                }
            }
        },
        // 启动腾讯云游戏
        startTencentGame(nsConn, onFailed) {
            this._ws_connection = nsConn;
            let that = this
            let autoRotateContainer = (that.isTencentMobileGame && helper.isMobile()) ? true : false;
            TCGSDK.init({
                'mount':'game-container',
                'appid':1251615365,
                'clickToFullscreen':false,
                'showLogo':false,
                'reconnect':true,
                'mobileGame': that.isTencentMobileGame,
                'autoRotateContainer': autoRotateContainer,
                'loadingText':'正在启动云游戏',
                onTouchEvent: (res) => {
                    if (TCGSDK.getInitOptions()?.mobileGame) return;
                    const { id, type, pageX, pageY } = res.pop();
                    // console.log('onTouchEvent', id, type, pageX, pageY);
                    TCGSDK.mouseMove(id, type, pageX, pageY);
                    if (type === 'touchstart') {
                        this.mouseLeft ? TCGSDK.sendRawEvent({ type: 'mouseleft', down: true }) : TCGSDK.sendRawEvent({ type: 'mouseright', down: true })
                    }
                    if (type === 'touchend' || type === 'touchcancel') {
                        TCGSDK.sendRawEvent({ type: 'mouseleft', down: false });
                        TCGSDK.sendRawEvent({ type: 'mouseright', down: false });
                    }
                },
                onDoubleTap: (res) => {
                    if (TCGSDK.getInitOptions()?.mobileGame) return;
                    const { id, type, pageX, pageY } = res.pop();
                    // console.log('onDoubleTap', id, type, pageX, pageY);
                    TCGSDK.mouseMove(id, type, pageX, pageY);
                    if (type === 'touchstart') {
                        TCGSDK.sendRawEvent({ type: 'mouseleft', down: true });
                    }
                    if (type === 'touchend' || type === 'touchcancel') {
                        TCGSDK.sendRawEvent({ type: 'mouseleft', down: false });
                    }
                },
                // 连接失败回调
                onConnectFail: function(ret){
                    // console.log(JSON.stringify(ret));
                    localGameState.stopGame("启动游戏失败");
                },
                // 连接成功回调
                onConnectSuccess: function(ret){
                    nsConn.emit("report", lcgWsUtil.wsMessageBody(10000, "success"));
                    if (that.$route.path.indexOf('phonegame')>0) {
                        TCGSDK.setCursorShowStat(false)
                    }
                    TCGSDK.setRemoteCursor(1);
                    that.dialogStatus = false;
                    that.gameSuccess = true;
                },
                //屏幕方向变化事件回调
                onOrientationChange: function (res){
                    if (that.isTencentMobileGame && res.type == 'landscape') {
                        that.mobileGameRotateContainer = true;
                    }
                },
                // 网络中断/被踢触发此回调
                // onDisconnect: function(ret){
                //     console.log(JSON.stringify(ret));
                //     if (ret.hasOwnProperty('code') && ret.code != 0) {
                //         localGameState.stopGame('已与服务器断开连接');
                //     }
                // },

                onNetworkChange:this.onTecentNetworkChanged,
                onWebrtcStat: function(ret) {
                    if(ret.code != 0) {
                        TCGSDK.destroy();
                        localGameState.stopGame('游戏加载失败/' + ret.code);
                    }
                },
                onLog:function(logText){
                    let report_msg = lcgWsUtil.wsMessageBody(10066,logText , "0", "", "", "");
                    nsConn.emit("report", report_msg);
                },

                // 初始化完毕的回调，触发此回调之后才能调用后面的 API
                onInitSuccess: function(ret) {
                    let cliSession = TCGSDK.getClientSession();
                    let report_msg = lcgWsUtil.wsMessageBody(10010, "换取serverSession", "1", "", "", cliSession);
                    nsConn.emit("report", report_msg);
                }
            });
        },
        onTecentNetworkChanged(event){
            let _status = event.status;
            switch(_status){
                case "stats":
                    this.rtt = event.stats.rtt;
                    this.bitrate = event.stats.bit_rate ? event.stats.bit_rate : 0;
                    this.bitrate = Math.round(this.bitrate/1024*8);
                    // console.table({
                    //     "延迟": this.rtt,
                    //     "码率": this.bitrate,
                    //     "fps": event.stats.fps || 0,
                    //     '云端CPU占用率': (event.stats.cpu || 0) + '%',
                    //     '云端加载时长(ms)': event.stats.load_cost_time || 0,
                    //     '客户端收到图像帧到解码显示的延时(ms)': event.stats.delay || 0
                    // });
                    let report_msg = lcgWsUtil.wsMessageBody(10066, this.rtt+"",'rtt');
                    this._ws_connection.emit("report", report_msg);
                    let report_msg2 = lcgWsUtil.wsMessageBody(10066, this.bitrate+"",'videoBitrate');
                    this._ws_connection.emit("report", report_msg2);
                    break;
            }
        },
        //启动华为云游戏
        startHuaweiGame(nsConn,onFailed) {
            this._ws_connection = nsConn;
            let params = {
                ip:  this.cloud_server,//  "lcg-hw-cph01.dygame.cn", //接入游戏使用的IP地址，即云手游服务器的EIP
                port: this.play_token.h5_port,
                package_name: this.game_params.package_name,
                launcher_activity: this.game_params.launcher_activity,
                app_id: this.game_params.app_id,
                session_id: this.session_id,
                game_timeout: this.play_token.game_timeout,//秒
                available_playtime: this.play_token.available_playtime,//10个小时,设置了没用，华为内部机制最长60s
                ticket: this.play_token.ticket,
                aes_key: this.play_token.aes_key,
                token: "abc",
                _debug: true,
            };

            let that = this
            if (CloudApp.isSupport()) {
                try {
                    // 建立连接华为云链接
                    const cloudApp = new CloudApp("game-container", params);
                    // open云服务器
                    // let success_msg = lcgWsUtil.wsMessageBody(10008);
                    // nsConn.emit("report", success_msg);

                    window.cloudApp = cloudApp;
                    this.cloudApp=cloudApp;
                    // 游戏的实时状态更新
                    cloudApp.on("appStateChange", function(event, data) {
                        let state = data && data.state; //华为云返回的数据
                        let _message = data.message;
                        let report_msg = lcgWsUtil.wsMessageBody(10066, _message,state);
                        nsConn.emit("report", report_msg);
                        switch (state){
                            case 2048://游戏启动成功
                                let success_msg = lcgWsUtil.wsMessageBody(10000, "success",state);
                                nsConn.emit("report", success_msg);
                                that.gameSuccess = true;
                                break;
                            case 4096://尤其切换到后台超时，导致链接断开
                                onFailed('Switch background timeout');
                                if(nsConn){
                                    nsConn.conn.close();
                                }
                                break;
                            case 769:
                                localGameState.stopGame('服务器无法访问');
                                break;
                            case 1539:
                            case 1540:
                            case 1541:
                            case 1542:
                            case 2305:
                            case 2306:
                            case 2307:
                            case 3331:
                            case 4352:
                            case 4865:
                                onFailed('云端游戏启动失败');
                                break;
                        }
                    });
                    // 游戏网络更新
                    cloudApp.on("netStateChange",this.onHuaweiCloudNetStateChange);
                    cloudApp.setVolume(60); //设置音量
                } catch (err) {
                    onFailed('云游戏初始化游戏失败: ' + err);
                }
            }
        },
        onHuaweiCloudNetStateChange( event, data){
            this.rtt=data.delay;
            this.bitrate = data.bitrate;
            let report_msg = lcgWsUtil.wsMessageBody(10066, this.rtt+"",'rtt');
            this._ws_connection.emit("report", report_msg);
            let report_msg2 = lcgWsUtil.wsMessageBody(10066, this.bitrate+"",'videoBitrate');
            this._ws_connection.emit("report", report_msg2);

        },
        // 启动Ubitus云游戏
        startUbitusGame(nsConn,onFailed) {
            this._ws_connection = nsConn;
            const config = {
                game: this.game_params.game_label,
                server:this.cloud_server,// "https://ubitusps2.riji001.com",
                width: this.gameWidth,
                height: this.gameHeight,
                target: "#game-container",
                auth: {
                    play_token: this.play_token.token,
                    provider_id: "t2cn",
                    channel: "t2cn_game",
                },
                launchParams: {
                    session: "new",
                    setting: {
                        autoviewport: true,
                        embed: true,
                        simpleloading: true,
                        slient:true,
                        toolbar:false
                    },
                },
            };
            OnNine.initializeAsync(config).then((onnine) => {
                this._onnine = onnine;
                onnine.addEventListener("status", this.onUbStatusChanged);
                onnine.addEventListener("error", function(error) {
                    //console.error(`Error: ${error.code}`);
                    //console.error(`Error: ${error.message}`);
                    let _err_code = error.code;
                    let _err_msg = error.message;
                    let str = {
                        action: 10066,
                        status: _err_code,
                        log_type: "Error",
                        msg: _err_msg ? _err_msg : "",
                    };
                    let report_msg=lcgWsUtil.wsMessageJSON(str);

                    nsConn.emit("report", report_msg);
                    nsConn.conn.close();
                    //提交游戏启动失败的消息
                    onFailed('云端游戏启动失败'+error.message);
                });

                onnine.addEventListener("rttinfo", this.onUbRttChanged);
                onnine.addEventListener("initialbitrate", function(_initialbitrate) {
                    // console.log("initialbitrate:"+_initialbitrate);
                });
                onnine.addEventListener("bitratechange", function(data) {
                    // console.log("bitratechange:"+JSON.stringify(data));
                });

                onnine.addEventListener('customEvent', function (msg) {
                    // console.log(`msg: ${msg}`);
                    window.open(msg,"_blank");
                });
                onnine.play();
            });
        },
        onUbStatusChanged(status){
            let report_msg = lcgWsUtil.wsMessageBody(10066, "Ubitus状态码",status);
            this._ws_connection.emit("report", report_msg);
            switch(status){
                case 'firstframearrived':
                    this._ws_connection.emit("report", lcgWsUtil.wsMessageBody(10000, "success"));
                    this.gameSuccess = true;
                    break;
                case 'close':
                    localGameState.stopGame('云端游戏关闭');
                    break
                case 'lowestbandwidth':
                    this.network_info='网络状态较差';
                    break;
                case 'normalbandwidth':
                    this.network_info='';
                    break;
            }
        },
        onUbRttChanged(_rtt){
            this.rtt=_rtt;
            let report_msg = lcgWsUtil.wsMessageBody(10066, this.rtt+"",'rtt');
            this._ws_connection.emit("report", report_msg);
        },
        // 启动阿里云游戏
        startAliGame(nsConn) {
            this._ws_connection = nsConn;
            let game_info = this.$store.state.game.game_info.launch_params
            const initParams = {
                userToken: game_info.play_token.user_token, // 商户⽤户token（⽤于鉴权）
                appKey: game_info.app_key, // 应⽤公钥
                userId: game_info.play_token.user_id,
                speedTest: false, // 为true时启动测试，建议游戏⻚该参数设置为false
                hidConfig: {
                    4: true, // 监听⿏标移动与点击
                    5: true, // 监听键盘按键
                },
            };
            let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            const prepareParams = {
                containerId: '#game-container',
                mixGameId: game_info.play_token.game_id,
                gameSession: game_info.play_token.game_session,
                width: this.gameWidth ? this.gameWidth : width,
                height: this.gameHeight,
            };
            this.acgCoreManager = CGSDK.getCoreManager();
            this.acgInteractManager = CGSDK.getInteractManager();
            this.acgCoreManager.init(initParams);
            CGSDK.addListener((handlerId, type, code, msg, level) => {
                // console.log(Date.now(), code, msg)
                let text = typeof msg !== "string" ? JSON.stringify(msg) : msg
                let report_msg = lcgWsUtil.wsMessageBody(10066, text, code);
                nsConn.emit("report", report_msg);
                switch (code) {
                    case '103010': // 初始化完成
                        this.acgCoreManager.prepare(prepareParams);
                        break;
                    case '201010': // 可以启动游戏
                        this.acgCoreManager.start()
                        break;
                    case '2701040': // 游戏启动成功
                        nsConn.emit("report", lcgWsUtil.wsMessageBody(10000, "success"));
                        this.dialogStatus = false;
                        this.gameSuccess = true;
                        this.ali_loading = false;
                        this.acgCoreManager.play()
                        break;
                    case '2809000':
                        this.rtt = msg.netWorkDelay
                        break;
                    // 关闭游戏的type
                    case '101030': // 找不到正确的appkey
                    case '101031': // 找不到正确的appsecret
                    case '101040': // 请求服务超时
                    case '101050': // ⽤户token校验未通过
                    case '101060': // ⼀级调度出错
                    case '101070': // 游戏数据不存在
                    case '101080': // ⽹络异常
                    case '101099': // 请求服务异常
                    case '102010': // 绑定⻓连接设备失败
                    case '102030': // 绑定⽤户失败
                    case '103020': // sdk初始化未完成
                    case '103030': // sdk初始化失败
                    case '201021': // 游戏启动失败
                    case '201022': // 游戏启动超时
                    case '201031': // 游戏脚本加载失败
                    case '201032': // 游戏脚本加载超时
                    case '301010': // 远程游戏插件包未加载成功，请稍后重试
                    case '301030': // 远程游戏插件包加载失败
                    case "501010":
                        localGameState.stopGame(msg);
                        this.ali_loading = false;
                        break;
                }
            });
        },
    }
}
